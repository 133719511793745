import React, { FC, useContext } from "react";
import { Context, IAsset, ISlider } from "src/common";
import FadeSlider from "src/common/components/FadeSlider/Slider";
import { FadeSliderWrapper, TextWrapper, HeroWrapper } from "./Hero.style";
import { FancyImage } from "../../../../../../common/BaseComponents/FancyImage/FancyImage";
import { css } from "styled-components";

const Hero: FC<React.PropsWithChildren<{ headline?: string }>> = ({ headline }) => {
    const props = useContext(Context);
    const imgArray = props.PageLanding?.elements?.filter((e) => e?.__typename === "Asset") as IAsset[];
    const slider = props.PageLanding?.elements?.filter((e) => e?.__typename === "Slider") as ISlider[];
    let imageOrGallery;

    if (slider && !!slider?.[0]?.elements) {
        imageOrGallery = (
            <FadeSliderWrapper>
                <FadeSlider elements={slider?.[0]?.elements} />
            </FadeSliderWrapper>
        );
    } else if (imgArray?.[0]) {
        if (imgArray.length > 1) {
            imageOrGallery = (
                <FadeSliderWrapper>
                    <FadeSlider elements={[...imgArray?.map((f) => f)]} />
                </FadeSliderWrapper>
            );
        } else if (imgArray?.length > 1) {
            imageOrGallery = (
                <FadeSliderWrapper>
                    <FadeSlider elements={imgArray} />
                </FadeSliderWrapper>
            );
        } else {
            imageOrGallery = (
                <div
                    css={css`
                        height: 440px;
                        > div {
                            height: 100%;
                        }
                    `}
                >
                    <FancyImage
                        img={imgArray?.[0]}
                        responsiveAspectRatio={{ bronze: 1, silver: 96 / 55, gold: 5 / 2 }}
                    />
                </div>
            );
        }
    }

    return (
        <HeroWrapper>
            {imageOrGallery ?? null}
            <TextWrapper>
                <h3>{headline}</h3>
            </TextWrapper>
        </HeroWrapper>
    );
};

export default Hero;
